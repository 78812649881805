/* Import de Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

div.blog-post h2 {
    @apply pt-12 font-extrabold text-4xl tracking-tight text-gray-900 dark:text-white;
}

div.blog-post p {
    @apply my-6 font-light text-gray-500 dark:text-gray-400;
}

div.blog-post p.alert-info {
    @apply p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800;
}

div.blog-post p.alert-warning {
    @apply p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800;
}

div.blog-post ul, div.blog-post ol {
    @apply max-w-3xl space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400;
}

div.blog-post a {
    @apply font-medium text-blue-600 dark:text-blue-500 hover:underline;
}

div.blog-post code {
    @apply font-mono text-sm text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 rounded-lg p-1;
}

div.blog-post img {
    @apply w-2/3 m-auto;
}

/* Import de Flowbite */
@import 'flowbite/dist/flowbite.css';
